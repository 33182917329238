import React, { FC } from "react";

import bg from "../assets/bg.png";
import vlLogo from "../assets/vl-logo.png";

import {
  AiFillYoutube,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";

const radiant = "radial-gradient(#402769,#D7558F,#F8AD4E,#D7558F,#1F1B33)";

const socials = [
  {
    name: "youtube",
    link: "https://www.youtube.com/@valarilabs",
    icon: AiFillYoutube,
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/valarilabs",
    icon: FaFacebook,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/valarilabs",
    icon: AiOutlineInstagram,
  },
  {
    name: "twitter",
    link: "https://twitter.com/ValariLabs",
    icon: AiFillTwitterCircle,
  },
  {
    name: "linkedIn",
    link: "https://www.linkedin.com/company/valarilabs",
    icon: AiFillLinkedin,
  },
];

const colorMap: Record<string, string> = {
  youtube: "#FF0000",
  instagram: "#C13584",
  twitter: "#1DA1F2",
  facebook: "#3b5998",
  linkedIn: "#0072b1",
};

export const Landing: FC = () => {
  return (
    <Flex
      color="#fff"
      fontFamily="Montserrat"
      minH="100vh"
      maxW="100vw"
      bg={radiant}
    >
      <Image src={bg} objectFit="cover" opacity={0.1} />
      <Center
        right={0}
        left={0}
        top={0}
        bottom={0}
        position="fixed"
        zIndex={5}
        flexDirection="column"
      >
        <Flex flexWrap="wrap" align="center" justify="center">
          <Flex align="center" minW="300px">
            <Image
              mr={4}
              src={vlLogo}
              objectFit="contain"
              maxW={{ base: "20vw", md: "10vw" }}
              userSelect="none"
            />
            <Flex mt="2vw" direction="column" justify="center">
              <Text
                fontSize={{ base: "10vw", md: "4vw" }}
                lineHeight={{ base: "30px", md: "50px" }}
                userSelect="none"
              >
                VALARI
              </Text>
              <Text
                fontWeight="bold"
                letterSpacing="1vw"
                fontSize={{ base: "10vw", md: "4vw" }}
                userSelect="none"
              >
                LABS
              </Text>
            </Flex>
          </Flex>
          <Box
            display={{ base: "none", md: "block" }}
            mx={10}
            w="8px"
            h="10vw"
            bg="#fff"
          />
          <Center>
            <Text
              fontSize="30px"
              fontWeight="600"
              letterSpacing="10px"
              textAlign="center"
              userSelect="none"
            >
              WE ARE BUILDING
            </Text>
          </Center>
        </Flex>
        <Text
          mt="60px"
          userSelect="none"
          fontWeight="600"
          letterSpacing="6px"
          textAlign="center"
        >
          CHECK OUT OUR SOCIAL MEDIA PROFILES
        </Text>
        <Flex mt={5} flexWrap="wrap" justify="center">
          {socials.map((i) => (
            <Box
              as="a"
              href={i.link}
              target="_blank"
              key={i.name}
              p={4}
              mx={6}
              my={2}
              bg="#ffffff40"
              cursor="pointer"
              borderRadius="50px"
              border="1px solid transparent"
              _hover={{
                border: "1px solid #fff",
                bg: "#ffffff70",
                transition: "all 0.5s ease",
                color: colorMap[i.name],
              }}
            >
              <i.icon size="50px" />
            </Box>
          ))}
        </Flex>
      </Center>
    </Flex>
  );
};
